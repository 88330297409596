import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["element", "tooltip"]
  static values = {
    placement: String,
    offset: Array
  }

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue || "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue.length > 0 ? this.offsetValue : [0, 12],
          },
        },
      ],
    })
  }

  show(event) {
    this.tooltipTarget.setAttribute("data-show", "")
    this.popperInstance.update()
  }

  hide(event) {
    this.tooltipTarget.removeAttribute("data-show")
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
  }
}
