import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = [ "allTasksChart", "task1Chart", "task2Chart", "task3Chart" ]

  connect() {
    this.initializeAllTasksChart()
    this.initializeTasksChart()
  }

  initializeAllTasksChart() {
    const percentComplete = this.allTasksChartTarget.dataset.percentComplete
    const percentIncomplete = 100 - percentComplete
    const data = {
      labels: ['Complete', 'Incomplete'],
      datasets: [{
        data: [percentComplete, percentIncomplete],
        backgroundColor: ['#95C64F', '#FFFFFF'],
        hoverOffset: 1,
        borderWidth: 0
      }]
    };

    new Chart(this.allTasksChartTarget, {
      type: 'pie',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          }
        }
      }
    })
  }

  initializeTasksChart() {
    const targets = [
      this.hasTask1ChartTarget ? this.task1ChartTarget : undefined,
      this.hasTask2ChartTarget ? this.task2ChartTarget : undefined,
      this.hasTask3ChartTarget ? this.task3ChartTarget : undefined
    ].filter(Boolean)

    targets.forEach(target => {
      const percentComplete = target.dataset.percentComplete
      const percentIncomplete = 100 - percentComplete
      const data = {
        labels: ['Complete', 'Incomplete'],
        datasets: [{
          data: [percentComplete, percentIncomplete],
          backgroundColor: ['#95C64F', '#F3F4F6'],
          hoverOffset: 1,
          borderWidth: 0
        }]
      };

      new Chart(target, {
        type: 'doughnut',
        data: data,
        options: {
          cutout: '70%',
          layout: {
            padding: 1
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false
            }
          }
        }
      })
    })
  }
}
