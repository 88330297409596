import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  onLinkClick(event) {
    const origin = event.target.closest("a");

    if (origin) {
      analytics.track("Link clicked", {
        url: origin.href,
        text: origin.text
      })
    }
  }

  connect() {
    this.contentTarget.addEventListener("click", this.onLinkClick)
  }

  disconnect() {
    this.contentTarget.removeEventListener("click", this.onLinkClick)
  }

  trackTwitterShare(event) {
    const origin = event.target.closest("a");

    if (origin) {
      analytics.track("Twitter sharing started", {
        url: origin.href
      })
    }
  }

  trackLinkedinShare(event) {
    const origin = event.target.closest("a");

    if (origin) {
      analytics.track("LinkedIn sharing started", {
        url: origin.href
      })
    }
  }
}
