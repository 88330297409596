import { Controller } from "@hotwired/stimulus"
import "intl-tel-input/build/css/intlTelInput.css"
import intlTelInput from "intl-tel-input"
import "intl-tel-input/build/js/utils.js"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  initPluginInstance() {
    this.plugin = intlTelInput(this.fieldTarget, {
      hiddenInput: this.fieldTarget.dataset.method,
      utilsScript: "intl-tel-input/build/js/utils.js",
      customContainer: "w-full"
    });
  }

  teardownPluginInstance() {
    if (this.plugin === undefined) { return }

    // revert to original markup, remove any event listeners
    this.plugin.destroy()
  }
}
