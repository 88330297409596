import { Controller } from "@hotwired/stimulus"
import List from "list.js"

export default class extends Controller {
  static targets = ["table", "searchInput"]
  static values = {
    columns: Array
  }

  connect() {
    this.columns = this.columnsValue.map(column => {
      if (typeof column === "string") {
        return `table-cell-${column}`
      } else {
        return `table-cell-${column.name}`
      }
    })
    this.tableInstance = new List(this.tableTarget, {
      valueNames: this.columns
    })
  }

  searchCells({ target: { value } }) {
    this.tableInstance.fuzzySearch(value, this.columns)
  }
}
