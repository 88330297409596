import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [
    "faqContainer",
    "faqTemplate",
    "customLineItemsContainer",
    "customLineItemTemplate",
  ]

  addFaqItem(event) {
    event.preventDefault()
    const index = new Date().getTime()
    const content = $(this.faqTemplateTarget).html().replaceAll("{{index}}", index)
    $(this.faqContainerTarget).append(content)
    $(".faq-delete-button").first().show()
  }

  removeFaqItem(event) {
    event.preventDefault()
    const index = $(event.target).data("index")
    const faqToRemove = $(this.faqContainerTarget).find(`.faq-item[data-index='${index}']`)
    faqToRemove.remove()

    if ($(".faq-item").length === 1) {
      $(".faq-delete-button").first().hide()
    }
  }

  addCustomLineItem(event) {
    event.preventDefault()
    const index = new Date().getTime()
    const content = $(this.customLineItemTemplateTarget).html().replaceAll("{{index}}", index)
    $(this.customLineItemsContainerTarget).append(content)
    $(".line-item-delete-button").first().show()
  }

  removeCustomLineItem(event) {
    event.preventDefault()
    const index = $(event.target).data("index")
    const lineItemToRemove = $(this.customLineItemsContainerTarget).find(`.custom-line-item[data-index='${index}']`)
    lineItemToRemove.addClass("hidden")
    lineItemToRemove.find("input[name*='_destroy']").val("1")

    if ($(".custom-line-item").length === 1) {
      $(".line-item-delete-button").first().hide()
    }
  }
}
