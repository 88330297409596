import { Controller } from "@hotwired/stimulus"

import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import TextAlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'

class ClassicEditor extends ClassicEditorBase {}

class CloudinaryUploadAdapter {
  constructor(loader, cloudName, uploadPreset, folder) {
    this.loader = loader;
    this.cloudName = cloudName;
    this.uploadPreset = uploadPreset;
    this.folder = folder;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
      }));
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open('POST', `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      resolve({
        default: response.url
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', this.uploadPreset);

    if (this.folder) {
      data.append('folder', this.folder);
    }

    this.xhr.send(data);
  }
}

function CloudinaryUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new CloudinaryUploadAdapter(
      loader,
      editor.config.get('cloudName'),
      editor.config.get('uploadPreset'),
      editor.config.get('folder')
    );
  };
}

ClassicEditor.builtinPlugins = [
  EssentialsPlugin,
  UploadAdapterPlugin,
  AutoformatPlugin,
  BoldPlugin,
  ItalicPlugin,
  BlockQuotePlugin,
  CloudServicesPlugin,
  EasyImagePlugin,
  HeadingPlugin,
  ImagePlugin,
  ImageCaptionPlugin,
  ImageStylePlugin,
  ImageToolbarPlugin,
  ImageUploadPlugin,
  LinkPlugin,
  ListPlugin,
  ParagraphPlugin,
  FontPlugin,
  MediaEmbedPlugin,
  ImageResizePlugin,
  TextAlignmentPlugin
]

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'fontcolor',
      'fontbackgroundcolor',
      'fontfamily',
      'fontsize',
      'link',
      'alignment',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'blockQuote',
      'mediaEmbed',
      'undo',
      'redo'
    ]
  },
  alignment: {
    options: [
      'left',
      'right',
      'center',
      'justify'
    ]
  },
  image: {
    resizeUnit: 'px',
    toolbar: [
      'imageStyle:full',
      'imageStyle:side',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignRight',
      '|',
      'toggleImageCaption',
      'imageTextAlternative'
    ],
    styles: [
      'full',
      'alignLeft',
      'alignCenter',
      'alignRight'
    ]
  },
  language: 'en',
  mediaEmbed: {
    previewsInData: true
  }
}

export default class extends Controller {
  static targets = [
    "field",
    "input"
  ]
  static values = {
    cloudName: String,
    uploadPreset: String,
    folder: String
  }

  connect() {
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  cleanupBeforeInit() {
    $(this.element).find('.ck').remove()
  }

  initPluginInstance() {
    if (!this.hasFieldTarget)
      return

    this.cleanupBeforeInit() // in case improperly torn down

    ClassicEditor.create(this.fieldTarget, {
      extraPlugins: [CloudinaryUploadAdapterPlugin],
      cloudName: this.cloudNameValue,
      uploadPreset: this.uploadPresetValue,
      folder: this.folderValue
    }).then((editor) => {
      editor.editing.view.change(writer => {
        writer.setStyle('min-height', '25em', editor.editing.view.document.getRoot());
      });

      if (this.fieldTarget.getAttribute('autofocus')) {
        editor.editing.view.focus();
      }

      this.plugin = editor

      this.fieldTarget.closest('form').addEventListener('submit', () => {
        this.inputTarget.value = this.plugin.getData()
      });
    });
  }

  teardownPluginInstance() {
    if (this.plugin === undefined)
      return

    // revert to original markup, remove any event listeners
    this.plugin.destroy()
  }
}
