import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleSignInViaForm"]
  static values = {
    hasPasswordSet: Boolean
  }

  handleToggle(event) {
    if ((event.target.checked && this.hasPasswordSetValue) || event.target.checked === false) {
      this.toggleSignInViaFormTarget.submit()
    }
  }
}
