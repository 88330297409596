import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal",
    "overlay",
    "checkoutLink",
    "closeButton"
  ]

  open(event) {
    const productId = event.currentTarget.dataset.productId
    this.checkoutLinkTarget.href = `/stripe/checkouts?product_ids=${productId}`
    this.modalTarget.classList.remove('hidden')
  }

  close(event) {
    if (event.currentTarget === this.closeButtonTarget || event.target === this.overlayTarget) {
      this.modalTarget.classList.add('hidden')
    }
  }
}
