import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  toggle(event) {
    const parent = event.currentTarget.closest(".accordion-section")
    parent.querySelectorAll(".toggleable").forEach((toggleable) => {
      toggleable.classList.toggle("hidden")
    })
  }
}
