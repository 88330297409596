import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "roleSelect",
    "subgroupsSelect"
  ]

  connect() {
    this.allMembersSelected = false

    $(this.roleSelectTargets).select2({
      minimumResultsForSearch: Infinity,
      theme: "default borderless"
    })

    $(this.subgroupsSelectTargets).select2({
      multiple: true,
      width: "style",
      minimumResultsForSearch: Infinity,
      theme: "default borderless"
    })

    $(this.roleSelectTargets).on("select2:select", ({ target: { value }}) => {
      if (this.allMembersSelected) {
        $(this.roleSelectTargets).val(value).trigger("change")
      }
    })
  }

  toggleMembersCheckboxes(isChecked) {
    for (const checkbox of document.querySelectorAll("input[name='select_member[]']")) {
      checkbox.checked = isChecked
    }
  }

  toggleAllMembers(event) {
    event.preventDefault()
    const button = event.target
    const isGoingToSelectAll = button.textContent === "Select all"
    this.toggleMembersCheckboxes(isGoingToSelectAll)
    this.allMembersSelected = isGoingToSelectAll
    button.textContent = isGoingToSelectAll ? "Unselect all" : "Select all"
  }
}
