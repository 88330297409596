$(document).on('turbo:load', function() {
  var endDateContainer = $('#end-date-container');
  var endDateInput = $('#member_experience_end_date');

  function toggleEndDateContainer(hide) {
    if (hide) {
      endDateInput.val('');
      endDateContainer.hide();
    } else {
      endDateContainer.show();
    }
  }

  function handleCurrentPositionChange(event) {
    var value = event.target.value;
    toggleEndDateContainer(value === 'true');
  }

  var currentPositionYesButton = $('#member_experience_current_position_true');
  var currentPositionNoButton = $('#member_experience_current_position_false');
  var currentPositionInput = $("input[name='member_experience[current_position]']");

  currentPositionInput.change(handleCurrentPositionChange);

  if (currentPositionYesButton.prop('checked')) {
    toggleEndDateContainer(true);
  }

  if (currentPositionNoButton.prop('checked')) {
    toggleEndDateContainer(false);
  }
})
