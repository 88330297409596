import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectInput",
    "existingMemberTemplate",
    "newMemberTemplate",
    "membershipsTable",
    "membershipsTableBody",
    "noMembersNotice",
    "submitButton"
  ]
  static values = {
    groupId: Number,
    teamId: Number
  }

  connect() {
    this.index = 0;
    this.membershipsAdded = {};
    this.initializeMembershipSelect();
    this.toggleVisibility();
  }

  disconnect() {
    this.removeSelectInput();
  }

  initializeMembershipSelect() {
    const options = {
      tags: true,
      minimumInputLength: 2,
      maximumSelectionSize: 1,
      multiple: true,
      width: 'style',
      templateResult: this.select2OptionTemplate,
      ajax: {
        url: $(this.selectInputTarget).data('search-url'),
        dataType: 'json',
        delay: 250,
        data: params => {
          return {
            search: params.term,
            page: params.page || 1,
            group_id: this.groupIdValue,
            from_team_id: this.teamIdValue
          }
        }
      }
    };
    this.select2Instance = $(this.selectInputTarget).select2(options);
    $(this.select2Instance).on('select2:selecting', (event) => this.onOptionSelected(event));
    $(this.select2Instance).on('select2:open', () => {
      setTimeout(() => document.querySelector('.select2-container--open .select2-search__field').focus(), 200)
    });
  }

  select2OptionTemplate(option) {
    if (option.email) {
      return $(
        `<div class="flex items-center group">
           <img src="${option.avatar_url}" class="h-5 w-5 rounded-full ml-4" />
           <span class="ml-4">${option.full_name}</span>
           <span class="ml-4">${option.email}</span>
           <span class="ml-4 text-gray-400 italic">${option.already_in_group ? "Already a member" : ""}</span>
         </div>`
      );
    } else {
      return $(`<span>Invite a new member</span>`);
    }
  }

  onOptionSelected(event) {
    event.preventDefault();
    this.select2Instance.select2('close');
    this.addGroupMembership(event.params.args.data);
  }

  addGroupMembership(membershipAdded) {
    if (Object.values(this.membershipsAdded).find(membership => membership.id === membershipAdded.id)) {
      alert("You have already selected this member.");
      return;
    }

    let content;

    if (membershipAdded.email) {
      content = $(this.existingMemberTemplateTarget).html()
        .replace("{{avatar_url}}", membershipAdded.avatar_url)
        .replace("{{full_name}}", membershipAdded.full_name)
        .replace("{{email}}", membershipAdded.email)
        .replace("{{membership_id}}", membershipAdded.id)
        .replaceAll('{{index}}', this.index);
    } else {
      content = $(this.newMemberTemplateTarget).html().replaceAll('{{index}}', this.index);
    }

    $(this.membershipsTableBodyTarget).append(content);
    this.membershipsAdded[this.index] = membershipAdded;
    this.index++;
    this.toggleVisibility();
  }

  removeGroupMembership(event) {
    event.preventDefault();
    const indexToRemove = $(event.target).data("index");
    $(this.membershipsTableBodyTarget).find("tr[data-index='" + indexToRemove + "']").remove();
    delete(this.membershipsAdded[indexToRemove]);
    this.toggleVisibility();
  }

  removeSelectInput() {
    this.select2Instance.select2('destroy');
  }

  toggleVisibility() {
    if (Object.keys(this.membershipsAdded).length === 0) {
      $(this.noMembersNoticeTarget).show();
      $(this.membershipsTableTarget).hide();
      this.submitButtonTarget.setAttribute("disabled", true);
    } else {
      $(this.noMembersNoticeTarget).hide();
      $(this.membershipsTableTarget).show();
      this.submitButtonTarget.removeAttribute("disabled");
    }
  }
}
