import '@hotwired/turbo-rails'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'select2/dist/css/select2.min.css'
import select2 from 'select2'
import jstz from 'jstz'
import Honeybadger from '@honeybadger-io/js'

import '../account'
import '../channels'
import '../concerns'
import '../controllers'
import '../electron'

import '../stylesheets/falcon/application.scss'
import '@icon/themify-icons/themify-icons.css'

window.jstz = jstz
window.Honeybadger = Honeybadger

window.googleMapsReady = () => {
  document.dispatchEvent(new Event('googleMapsReady'))
}

if (!window.Rails) Rails.start()
ActiveStorage.start()
select2()

$(document).on('turbo:load', () => {
  $('.alert-container:not(.persistent)').delay(15000).fadeOut('fast')
  $('.alert-container .btn-close-alert').click(event => {
    $(event.target).closest('.alert-container').hide()
  })

  document.body.addEventListener('click', event => {
    if (event.target.matches('.disabled') || event.target.closest('.disabled')) {
      event.preventDefault()
    }
  })
})
