import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "panel",
    "backdrop"
  ]

  connect() {
    this.eventDetail = {}
  }

  openModal(event) {
    const targetModalId = event.detail.modalId

    if (this.element.id === targetModalId) {
      this.eventDetail = event.detail
      this.backdropTarget.classList.remove("bg-opacity-0")
      this.backdropTarget.classList.remove("hidden")
      this.panelTarget.classList.remove("hidden")
      this.backdropTarget.classList.add("bg-opacity-75")
      this.backdropTarget.classList.add("fixed")
      this.panelTarget.classList.add("fixed")
    }
  }

  dismiss() {
    this.backdropTarget.classList.add("bg-opacity-0")
    this.backdropTarget.classList.add("hidden")
    this.panelTarget.classList.add("hidden")
    this.backdropTarget.classList.remove("bg-opacity-75")
    this.backdropTarget.classList.remove("fixed")
    this.panelTarget.classList.remove("fixed")
  }
}
