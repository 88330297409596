import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "template", "subgroupsList", "noSubgroupsNotice" ]

  connect() {
    this.toggleVisibility();
  }

  addSubgroup(event) {
    event.preventDefault();
    const index = new Date().getTime();
    const content = $(this.templateTarget).html().replaceAll("{{index}}", index);
    $(this.subgroupsListTarget).append(content);
    this.toggleVisibility();
  }

  removeSubgroup(event) {
    event.preventDefault();
    const indexToRemove = $(event.target).data("index");
    const isNewRecord = $(event.target).data("newRecord");
    const subgroupToRemove = $(this.subgroupsListTarget).find(`.subgroup[data-index='${indexToRemove}']`);

    if (isNewRecord) {
      subgroupToRemove.remove();
    } else {
      $(`#group_subgroups_attributes_${indexToRemove}__destroy`).val(true);
      subgroupToRemove.hide();
    }

    this.toggleVisibility();
  }

  toggleVisibility() {
    const subgroupsVisibleCount = $(".subgroup:not([style='display: none;'])").length;

    if (subgroupsVisibleCount === 0) {
      $(this.noSubgroupsNoticeTarget).show();
      $(this.subgroupsListTarget).hide();
    } else {
      $(this.noSubgroupsNoticeTarget).hide();
      $(this.subgroupsListTarget).show();
    }
  }
}
