import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "openEyeIcon", "eyeSlashIcon" ]

  connect() {
    const field = this.fieldTarget

    if (this.hasOpenEyeIconTarget && this.hasEyeSlashIconTarget) {
      const openEyeIcon = this.openEyeIconTarget
      const eyeSlashIcon = this.eyeSlashIconTarget
      const icons = [this.openEyeIconTarget, this.eyeSlashIconTarget]

      icons.forEach(target => {
        target.addEventListener("click", function() {
          const newType = field.getAttribute("type") === "password" ? "text" : "password"
          field.setAttribute("type", newType)
          openEyeIcon.classList.toggle("hidden")
          eyeSlashIcon.classList.toggle("hidden")
        })
      })
    }
  }
}
