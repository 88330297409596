const minimumInputLength = 2
const delayBetweenRequests = 500
let sessionToken

function defineGoogleAutocompleteDataAdapter() {
  const ArrayAdapter = $.fn.select2.amd.require("select2/data/array")

  // patch for select2 that removes the entire tag when using backspace
  const Search = $.fn.select2.amd.require("select2/selection/search")
  const oldRemoveChoice = Search.prototype.searchRemoveChoice
  Search.prototype.searchRemoveChoice = function() {
    oldRemoveChoice.apply(this, arguments)
    this.$search.val('')
  }

  return class extends ArrayAdapter {
    constructor($element, options) {
      super($element, options)

      this.autocompleteService = new google.maps.places.AutocompleteService()
      this.placesService = new google.maps.places.PlacesService(document.getElementById("places-map"))
      sessionToken = new google.maps.places.AutocompleteSessionToken()
    }

    query(params, callback) {
      const data = {results: []}

      if (params.term && params.term !== '' && params.term.length >= minimumInputLength) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }

        this.timeoutId = setTimeout(() => {
          const options = {
            input: params.term,
            types: ["(regions)"],
            sessionToken: sessionToken,
            componentRestrictions: {
              country: "us"
            }
          }
          this.autocompleteService.getPlacePredictions(options).then(response => {
            response.predictions.forEach(prediction => {
              data.results.push({
                id: prediction.description,
                text: prediction.description,
                placeId: prediction.place_id
              })
            })
            callback(data)
          })
        }, delayBetweenRequests)
      } else {
        callback(data)
      }
    }
  }
}

const GoogleAutocompleteDataAdapter = new Promise((resolve) => {
  $(document).ready(function() {
    const GoogleAutocompleteDataAdapter = defineGoogleAutocompleteDataAdapter()

    // patch for our custom select2 adapter that fetches the place details
    // when user selects one suggestion (making the request free of charge)
    // https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#ac-with-details-session
    const oldSelectMethod = GoogleAutocompleteDataAdapter.prototype.select
    GoogleAutocompleteDataAdapter.prototype.select = function(data) {
      const options = {
        placeId: data.placeId,
        fields: ["formatted_address"],
        sessionToken: sessionToken
      }
      this.placesService.getDetails(options, (place, status) => {
        sessionToken = new google.maps.places.AutocompleteSessionToken()
      })

      oldSelectMethod.bind(this)(data)
    }

    resolve(GoogleAutocompleteDataAdapter)
  })
})

export default GoogleAutocompleteDataAdapter
