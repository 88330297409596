import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copyPostLink(event) {
    event.preventDefault()
    event.stopPropagation()

    navigator.clipboard.writeText(event.target.dataset.postUrl)
    event.target.innerText = "Link Copied!"
    setTimeout(() => {
      event.target.dispatchEvent(new CustomEvent("close-share-dropdown", { bubbles: true }))

      setTimeout(() => {
        event.target.innerText = "Copy Link"
      }, 100)
    }, 1000)
  }

  shareOnTwitter(event) {
    event.preventDefault()
    event.stopPropagation()

    const postTitle = event.target.dataset.postTitle
    const postUrl = event.target.dataset.postUrl
    window.open(`https://twitter.com/intent/tweet?text=${postTitle} @PE_CXO&url=${postUrl}`, "_blank").focus()
    event.target.dispatchEvent(new CustomEvent("close-share-dropdown", { bubbles: true }))
  }

  shareOnLinkedin(event) {
    event.preventDefault()
    event.stopPropagation()

    const postUrl = event.target.dataset.postUrl
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`, "_blank").focus()
    event.target.dispatchEvent(new CustomEvent("close-share-dropdown", { bubbles: true }))
  }
}
