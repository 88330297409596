import { Controller } from "@hotwired/stimulus"
import "jquery-validation/dist/jquery.validate.min"
import "jquery-validation/dist/additional-methods.min"

export default class extends Controller {
  static targets = [
    "resumeField",
    "resumeContainer",
    "removalInput"
  ]

  connect() {
    this.populateResumeField()
    this.initializeValidation()
  }

  populateResumeField() {
    let filename = ""

    fetch("/account/users/resume").then(response => {
      if (response.ok) {
        const header = response.headers.get("Content-Disposition")
        filename = decodeURIComponent(header.match(/filename="(.+)"/)[1])
        return response.blob()
      } else if (response.status === 404) {
        throw new Error("The user's resume was not found.")
      } else {
        throw new Error(`There was a problem when fetching the user's resume: ${response.statusText}`)
      }
    }).then(blob => {
      const file = new File([blob], filename, { type: blob.type })
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      this.resumeFieldTarget.files = dataTransfer.files
      this.onResumeSelected()
    }).catch(error => {
      if (error.message.startsWith("There was a problem when fetching the user's resume")) {
        Honeybadger.notify(error)
      }

      console.error(error)
    })
  }

  initializeValidation() {
    const _this = this

    $.validator.addMethod('filesize', function (value, element, param) {
      return this.optional(element) || (element.files[0].size <= param)
    }, function(size) {
      return `The maximum file size is ${Math.round(size / (1024 * 1024))}MB.`
    })

    $("#member-demographics-form").validate({
      ignore: "input[type=hidden]",
      onfocusout: function(element) {
        if ($(element).hasClass("validate-on-blur")) {
          this.element(element)
        }
      },
      invalidHandler: function(event, validator) {
        if (validator.numberOfInvalids() === 0)
          return

        $("html, body").animate({
          scrollTop: $(validator.errorList[0].element).closest(":visible").offset().top - 100
        }, 500)
      },
      rules: {
        "member_demographic[user_attributes][resume]": {
          required: false,
          accept: "application/pdf",
          filesize: 26214400
        }
      },
      messages: {
        "member_demographic[user_attributes][resume]": {
          accept: "Please select a PDF file."
        }
      },
      errorClass: "block text-xs text-red mt-1",
      errorPlacement: function (label, element) {
        label.insertAfter(element.parent().parent())
      }
    })
  }

  openResumeDialog(event) {
    event.preventDefault()
    this.resumeFieldTarget.click()
  }

  onResumeSelected() {
    const file = this.resumeFieldTarget.files[0]

    if (file) {
      $(this.resumeContainerTarget).find(".upload-file i").removeClass("ti-upload").addClass("ti-check")
      $(this.resumeContainerTarget).find(".upload-file span").text("File Uploaded")
      $(this.resumeContainerTarget).find(".file-name").removeClass("hidden").find("span").text(file.name)
      $(this.resumeFieldTarget).valid()
      this.removalInputTarget.value = false
    } else {
      this.removeResume(new Event("blank"))
    }
  }

  removeResume(event) {
    event.preventDefault()
    $(this.resumeContainerTarget).find(".upload-file i").removeClass("ti-check").addClass("ti-upload")
    $(this.resumeContainerTarget).find(".upload-file span").text("Upload New Document")
    $(this.resumeContainerTarget).find(".file-name").addClass("hidden")
    $(this.resumeFieldTarget).val(null).change()
    this.removalInputTarget.value = true
  }
}
