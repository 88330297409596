import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'form',
    'notifyMe'
  ]
  static values = {
    role: String,
    industries: String
  }

  connect() {
    this.timeout = null
    this.inputs = document.querySelectorAll(
      "input[name='notify_me']," +
      "input[name='user[job_preferences_current_interest]']," +
      "input[name='user[job_preferences_roles][]']," +
      "input[name='user[job_preferences_industries][]']"
    )
    this.inputs.forEach(input => {
      input.addEventListener('change', this.submit.bind(this))
    })

    if (this.notifyMeTarget.checked) {
      this.containerTarget.classList.remove('hidden')
    }
  }

  disconnect() {
    this.inputs.forEach(input => {
      input.removeEventListener('change', this.submit.bind(this))
    })
  }

  toggle() {
    document.querySelector(`input[name="user[job_preferences_roles][]"][value="${this.roleValue}"]`).checked = this.notifyMeTarget.checked

    this.industriesValue.split(';').forEach(industry => {
      const input = document.querySelector(`input[name="user[job_preferences_industries][]"][value="${industry}"]`)

      if (input) {
        input.checked = this.notifyMeTarget.checked
      }
    })

    if (this.notifyMeTarget.checked) {
      document.querySelector(`input[name="user[job_preferences_notifications]"]`).value = 'true'
    }

    this.containerTarget.classList.toggle('hidden')
    this.formTarget.requestSubmit()
  }

  submit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.formTarget.requestSubmit(), 1000)
  }
}
