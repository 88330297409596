import TableController from "./table_controller";

export default class extends TableController {
  connect() {
    super.connect();
  }

  toggleRemovedMembers(event) {
    event.preventDefault()
    const button = event.target
    const isGoingToShowRemoved = button.textContent === "Show converted/removed members"
    button.textContent = isGoingToShowRemoved ? "Hide converted/removed members" : "Show converted/removed members"

    this.tableInstance.items.forEach(item => {
      if (item.elm.dataset.inactive === "true") {
        item.elm.classList.toggle("hidden")
      }
    })
  }
}
