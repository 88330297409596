import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectInput"
  ]

  connect() {
    this.initializeStatusSelect()
  }

  initializeStatusSelect() {
    const options = {
      width: 'style',
      minimumResultsForSearch: Infinity,
      templateResult: this.select2OptionTemplate
    }
    this.select2Instance = $(this.selectInputTarget).select2(options)
    $(this.select2Instance).on('select2:open', () => {
      setTimeout(() => document.querySelector('.select2-container--open .select2-search__field').focus(), 200)
    })
  }

  select2OptionTemplate(option) {
    if (option.element) {
      return $(
        `<div class="flex items-center group">
           <span class="ml-4 hover:font-semibold">${option.text}</span>
           <span class="ml-4 text-gray-400 italic">
             ${option.element.dataset.helperText ? option.element.dataset.helperText : ""}
           </span>
         </div>`
      )
    } else {
      return ""
    }
  }

  disconnect() {
    this.select2Instance.select2('destroy')
  }
}
