import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fileInput",
    "uploadFilesSpan",
    "uploadFilesButton",
    "clearFilesButton"
  ]
  static values = {
    fileLabel: String
  }

  connect() {
    this.fileLabelValue = this.hasFileLabelValue ? this.fileLabelValue : 'File'
  }

  showFilenames(event) {
    this.uploadFilesSpanTarget.innerHTML = `Change ${this.fileLabelValue}(s)`
    this.clearFilesButtonTarget.classList.remove('hidden')
  }

  addFiles(event) {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  clearFiles(event) {
    event.preventDefault()
    this.fileInputTarget.value = ''
    this.uploadFilesSpanTarget.innerHTML = `Upload New ${this.fileLabelValue}(s)`
    this.clearFilesButtonTarget.classList.add('hidden')
  }
}
