import { Controller } from "@hotwired/stimulus"

const YOUTUBE_VIDEO_PLAYING_STATE = 1

export default class extends Controller {
  static targets = []
  static values = {
    googleApiKey: String
  }

  connect() {
    this.players = []
    this.youTubeTrackingInterval = undefined

    if (this.googleApiKeyValue.length > 0) {
      $(".wp-block-embed.is-type-video.is-provider-youtube iframe").each(function() {
        const src = $(this).attr("src")

        if (src.indexOf("?") >= 0) {
          $(this).attr("src", `${src}&enablejsapi=1`)
        } else {
          $(this).attr("src", `${src}?enablejsapi=1`)
        }
      })

      window.onYouTubeIframeAPIReady = this.initializeYouTubePlayers.bind(this)
    }

    $("<script>", { src: "https://www.youtube.com/iframe_api" }).insertBefore($("script").first())
  }

  initializeYouTubePlayers() {
    let _this = this

    $(".wp-block-embed.is-type-video.is-provider-youtube iframe").each(function() {
      _this.players.push(
        new YT.Player($(this)[0], {
          height: "360",
          width: "640",
          videoId: $(this).attr("src").match(/embed\/(.*)\?/)[1],
          events: {
            "onStateChange": _this.onYouTubePlayerStateChange.bind(_this)
          }
        })
      )
    })

    document.addEventListener("segment-analytics-loaded", () => {
      analytics.ready(this.initializeYouTubeAnalytics.bind(this))
    })
  }

  initializeYouTubeAnalytics() {
    this.players.forEach(player => {
      const youTubeAnalytics = new window.analytics.plugins.YouTubeAnalytics(player, this.googleApiKeyValue)
      youTubeAnalytics.initialize()
    })
  }

  onYouTubePlayerStateChange(event) {
    if (event.data === YOUTUBE_VIDEO_PLAYING_STATE) {
      clearInterval(this.youTubeTrackingInterval)
      this.youTubeTrackingInterval = this.trackCurrentYouTubeStatus(event.target)
    }
  }

  trackCurrentYouTubeStatus(player) {
    return setInterval(() => {
      if (player.playerInfo.playerState === YOUTUBE_VIDEO_PLAYING_STATE) {
        analytics.track("Video Playing", {
          position: player.playerInfo.currentTime,
          quality: player.playerInfo.playbackQuality,
          sound: player.playerInfo.muted ? 0 : player.getVolume(),
          total_length: player.playerInfo.duration,
          video_player: "youtube"
        })
      }
    }, 30000)
  }
}
