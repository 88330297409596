import { Controller } from "@hotwired/stimulus"
import GoogleAutocompleteDataAdapter from "../adapters/google_autocomplete_data_adapter"

export default class extends Controller {
  static targets = [
    "currentLocation",
    "relocationPreferences",
    "relocationPreferencesDiv",
    "openToRelocation",
    "selfDescribedGender",
    "selfDescribedPronouns",
    "selfDescribedEthnicity",
    "selfDescribedDisability"
  ]

  connect() {
    if (window?.google?.maps?.places) {
      this.initializeCurrentLocationField()
      this.initializeRelocationPreferencesSelect()
    } else {
      document.addEventListener("googleMapsReady", () => {
        this.initializeCurrentLocationField()
        this.initializeRelocationPreferencesSelect()
      })
    }

    this.listenForClicks()
    if (this.hasSelfDescribedGenderTarget) this.addListenerToGenderField()
    if (this.hasSelfDescribedPronounsTarget) this.addListenerToPronounsField()
    if (this.hasSelfDescribedEthnicityTarget) this.addListenerToEthnicityField()
    if (this.hasSelfDescribedEthnicityTarget) this.addListenerToDisabilityField()
  }

  initializeCurrentLocationField() {
    new google.maps.places.Autocomplete(
      this.currentLocationTarget,
      {
        types: ["(regions)"],
        componentRestrictions: {country: "us"}
      }
    )
  }

  initializeRelocationPreferencesSelect() {
    GoogleAutocompleteDataAdapter.then((AutocompleteClass) => {
      this.relocationPreferencesSelect = $(this.relocationPreferencesTarget).select2({
        width: "style",
        multiple: true,
        dataAdapter: AutocompleteClass,
        placeholder: "Start typing a city or state"
      })
      this.relocationPreferencesSelect.on("select2:selecting", function(event) {
        const text = event.params.args.data.text
        const selectedOptions = $(this).val() || []
        const newLength = [...selectedOptions, text].join("; ").length

        if (newLength > 255) {
          alert("You have selected too many locations. Please remove some before adding more.")
          event.preventDefault()
        }
      })
    })
  }

  onOpenToRelocationChange(event) {
    const isChecked = event.target.value === "true"

    if (isChecked) {
      this.relocationPreferencesDivTarget.classList.remove("absolute", "invisible")
    } else {
      this.relocationPreferencesDivTarget.classList.add("absolute", "invisible")
      $(this.relocationPreferencesTarget).empty()
    }
  }

  listenForClicks() {
    const _this = this
    const form = $('#member-demographics-form')
    let isFormDirty = false

    form.on('change', ':input', function() {
      isFormDirty = true
    })

    document.addEventListener('click', (event) => {
      const tagName = event.target.tagName

      if (tagName.toLowerCase() === 'a' && isFormDirty) {
        event.preventDefault()
        window.dispatchEvent(
          new CustomEvent("open-modal", {
            bubbles: true,
            detail: {
              modalId: "unsaved-changes-modal",
              route: event.target.href
            }
          })
        )
      }
    })
  }

  addListenerToGenderField() {
    const selfDescribedGenderDiv = $(this.selfDescribedGenderTarget)
    const selfDescribedGenderInput = selfDescribedGenderDiv.find("input")

    $("select[name='member_demographic[gender]']").change(function(event) {
      if (event.target.value === "Self-Describe") {
        selfDescribedGenderDiv.show()
      } else {
        selfDescribedGenderDiv.hide()
        selfDescribedGenderInput.val("")
      }
    })
  }

  addListenerToPronounsField() {
    const selfDescribedPronounsDiv = $(this.selfDescribedPronounsTarget)
    const selfDescribedPronounsInput = selfDescribedPronounsDiv.find("input")

    $("select[name='member_demographic[pronouns]']").change(function(event) {
      if (event.target.value === "Self-Describe:") {
        selfDescribedPronounsDiv.show()
      } else {
        selfDescribedPronounsDiv.hide()
        selfDescribedPronounsInput.val("")
      }
    })
  }

  addListenerToEthnicityField() {
    const selfDescribedEthnicityDiv = $(this.selfDescribedEthnicityTarget)
    const selfDescribedEthnicityInput = selfDescribedEthnicityDiv.find("input")

    $("select[name='member_demographic[ethnicity][]']").change(function(event) {
      if ($(event.target).val().includes("Self Describe:")) {
        selfDescribedEthnicityDiv.show()
      } else {
        selfDescribedEthnicityDiv.hide()
        selfDescribedEthnicityInput.val("")
      }
    })
  }

  addListenerToDisabilityField() {
    const selfDescribedDisabilityDiv = $(this.selfDescribedDisabilityTarget)
    const selfDescribedDisabilityInput = selfDescribedDisabilityDiv.find("input")

    $("select[name='member_demographic[disability_or_impairment][]']").change(function(event) {
      if ($(event.target).val().includes("Other disability/impairment:")) {
        selfDescribedDisabilityDiv.show()
      } else {
        selfDescribedDisabilityDiv.hide()
        selfDescribedDisabilityInput.val("")
      }
    })
  }
}
