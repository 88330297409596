import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fileInput",
    "uploadImageSpan",
    "clearImageButton",
    "downloadImageContainer",
    "uploadIcon",
    "imageRemovalInput"
  ]

  handleImageSelection() {
    this.uploadIconTarget.classList.remove("ti-upload")
    this.uploadIconTarget.classList.add("ti-check")
    this.uploadImageSpanTarget.innerText = "Select Another Image"

    if (this.hasDownloadImageContainerTarget) {
      this.downloadImageContainerTarget.remove()
    }
  }

  addImage(event) {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  clearImage(event) {
    event.preventDefault()

    this.downloadImageContainerTarget.classList.add("hidden")
    this.clearImageButtonTarget.classList.add("hidden")
    this.imageRemovalInputTarget.value = true
  }
}
