import { Controller } from "@hotwired/stimulus"
import GoogleAutocompleteDataAdapter from "../adapters/google_autocomplete_data_adapter"
import "jquery-validation/dist/jquery.validate.min"
import "jquery-validation/dist/additional-methods.min"

export default class extends Controller {
  static targets = [
    "currentLocation",
    "relocationPreferences",
    "relocationPreferencesDiv",
    "genderSelect",
    "ethnicitySelect",
    "disabilitySelect",
    "pronounsSelect",
    "selfDescribedGender",
    "selfDescribedEthnicity",
    "selfDescribedDisability",
    "selfDescribedPronouns",
    "errors",
    "submitButton",
    "resumeField",
    "resumeContainer",
    "saveToProfileButton"
  ]
  static values = {
    userId: Number
  }

  connect() {
    if (window?.google?.maps?.places) {
      this.initializeCurrentLocationField()
      this.initializeRelocationPreferencesSelect()
    } else {
      document.addEventListener("googleMapsReady", () => {
        this.initializeCurrentLocationField()
        this.initializeRelocationPreferencesSelect()
      })
    }

    this.initializeValidation()
    this.initializeGenderSelect()
    this.initializePronounsSelect()
    this.initializeEthnicitySelect()
    this.initializeDisabilitySelect()
    this.listenForChanges()
    this.scrollToErrors()
  }

  findValidationTarget(element) {
    if ($(element).is("select") || $(element).is(":file")) {
      return $(element).next()
    } else if ($(element).is(":radio")) {
      return $(document.getElementsByName($(element).attr("name"))).nextAll()
    } else {
      return $(element)
    }
  }

  initializeCurrentLocationField() {
    new google.maps.places.Autocomplete(
      this.currentLocationTarget,
      {
        types: ["(regions)"],
        componentRestrictions: {country: "us"}
      }
    )
  }

  initializeRelocationPreferencesSelect() {
    GoogleAutocompleteDataAdapter.then((AutocompleteClass) => {
      this.relocationPreferencesSelect = $(this.relocationPreferencesTarget).select2({
        width: "style",
        multiple: true,
        dataAdapter: AutocompleteClass,
        placeholder: "Start typing a city or state"
      })
      this.relocationPreferencesSelect.on("select2:selecting", function(event) {
        const text = event.params.args.data.text
        const selectedOptions = $(this).val() || []
        const newLength = [...selectedOptions, text].join("; ").length

        if (newLength > 255) {
          alert("You have selected too many locations. Please remove some before adding more.")
          event.preventDefault()
        }
      })
    })
  }

  initializeGenderSelect() {
    this.genderSelect = $(this.genderSelectTarget).select2()
    $(this.genderSelect).change(event => this.onGenderSelect(event.target.value))
    $(this.genderSelect).trigger("change")
  }

  initializePronounsSelect() {
    this.pronounsSelect = $(this.pronounsSelectTarget).select2()
    $(this.pronounsSelect).change(event => this.onPronounsSelect(event.target.value))
    $(this.pronounsSelect).trigger("change")
  }

  initializeEthnicitySelect() {
    this.ethnicitySelect = $(this.ethnicitySelectTarget).select2()
    $(this.ethnicitySelect).change(event => this.onEthnicitySelect(event.target.selectedOptions))
    $(this.ethnicitySelect).trigger("change")
  }

  initializeDisabilitySelect() {
    this.disabilitySelect = $(this.disabilitySelectTarget).select2()
    $(this.disabilitySelect).change(event => this.onDisabilitySelect(event.target.selectedOptions))
    $(this.disabilitySelect).trigger("change")
  }

  onOpenToRelocationChange(event) {
    const isChecked = event.target.value === "true"

    if (isChecked) {
      this.relocationPreferencesDivTarget.classList.remove("hidden")
    } else {
      this.relocationPreferencesDivTarget.classList.add("hidden")
      $(this.relocationPreferencesTarget).empty()
    }
  }

  onGenderSelect(value) {
    if (value === "Self-Describe") {
      $(this.selfDescribedGenderTarget).show()
      $("#job_application_self_described_gender").rules("add", "required")
    } else {
      $(this.selfDescribedGenderTarget).hide()
      $("#job_application_self_described_gender").rules("remove", "required")
    }
  }

  onPronounsSelect(value) {
    if (value === "Self-Describe:") {
      $(this.selfDescribedPronounsTarget).show()
      $("#job_application_self_described_pronouns").rules("add", "required")
    } else {
      $(this.selfDescribedPronounsTarget).hide()
      $("#job_application_self_described_pronouns").rules("remove", "required")
    }
  }

  onEthnicitySelect(selectedOptions) {
    const values = Array.from(selectedOptions).map(({ value }) => value)

    if (values.includes("Self Describe:")) {
      $(this.selfDescribedEthnicityTarget).show()
      $("#job_application_self_described_ethnicity").rules("add", "required")
    } else {
      $(this.selfDescribedEthnicityTarget).hide()
      $("#job_application_self_described_ethnicity").rules("remove", "required")
    }
  }

  onDisabilitySelect(selectedOptions) {
    const values = Array.from(selectedOptions).map(({ value }) => value)

    if (values.includes("Other disability/impairment:")) {
      $(this.selfDescribedDisabilityTarget).show()
      $("#job_application_self_described_disability").rules("add", "required")
    } else {
      $(this.selfDescribedDisabilityTarget).hide()
      $("#job_application_self_described_disability").rules("remove", "required")
    }
  }

  listenForChanges() {
    const _this = this

    $("#job_application_current_location, #job_application_current_title, #job_application_linkedin_url").on("input", function() {
      _this.saveToProfileButtonTarget.disabled = !$(this).valid()
    })

    $("#job_application_current_location, #job_application_current_title, input[name='job_application[open_to_relocation]'], " +
      "#job_application_relocation_preferences, #job_application_current_company_revenue, " +
      "input[name='job_application[private_equity_experience]'], #job_application_sector_experience, " +
      "input[name='job_application[private_equity_sale_process_experience]'], #job_application_linkedin_url, " +
      "#job_application_resume").on("change", function() {
        _this.saveToProfileButtonTarget.disabled = !$(this).valid()
    })
  }

  scrollToErrors() {
    if (this.errorsTarget.querySelector("div") !== null) {
      this.errorsTarget.scrollIntoView()
    }
  }

  initializeValidation() {
    const _this = this

    $.validator.addMethod('filesize', function (value, element, param) {
      return this.optional(element) || (element.files[0].size <= param)
    }, function(size) {
      return `The maximum file size is ${Math.round(size / (1024 * 1024))}MB.`
    })

    $.validator.addMethod('greaterThanZeroIfFilled', function(value, element) {
      return this.optional(element) || (value > 0)
    }, 'The value must be positive.')

    $("#job-application-form").validate({
      ignore: "input[type=hidden]",
      onfocusout: function(element) {
        if ($(element).hasClass("validate-on-blur")) {
          this.element(element)
        }
      },
      invalidHandler: function(event, validator) {
        if (validator.numberOfInvalids() === 0)
          return

        $("html, body").animate({
          scrollTop: $(validator.errorList[0].element).closest(":visible").offset().top - 100
        }, 500)
      },
      rules: {
        "job_application[current_location]": "required",
        "job_application[current_title]": "required",
        "job_application[linkedin_url]": "required",
        "job_application[open_to_relocation]": "required",
        "job_application[current_company_revenue]": "required",
        "job_application[private_equity_experience]": "required",
        "job_application[private_equity_sale_process_experience]": "required",
        "job_application[sector_experience][]": "required",
        "job_application[resume]": {
          required: true,
          accept: "application/pdf",
          filesize: 26214400
        },
        "job_application[birth_year]": {
          number: true,
          greaterThanZeroIfFilled: true
        }
      },
      messages: {
        "job_application[resume]": {
          required: "This field is required.",
          accept: "Please select a PDF file."
        }
      },
      errorClass: "block text-xs text-red mt-1",
      highlight: function (element, errorClass, validClass) {
        const target = _this.findValidationTarget(element)
        target.addClass("has-error")
        $(element.form).find("label[for=" + element.id + "]").addClass(errorClass);
      },
      unhighlight: function (element, errorClass, validClass) {
        const target = _this.findValidationTarget(element)
        target.removeClass("has-error")
        $(element.form).find("label[for=" + element.id + "]").removeClass(errorClass);
      },
      errorPlacement: function (label, element) {
        if (element.is(":radio") || element.is("select") || element.is(":file")) {
          label.insertAfter(element.parent().parent())
        } else {
          label.insertAfter(element)
        }
      }
    })
  }

  getOpenToRelocationValue() {
    let value = null

    document.getElementsByName("job_application[open_to_relocation]").forEach(element => {
      if (element.checked) {
        value = element.value
      }
    })

    return value
  }

  getExperienceInPrivateEquity() {
    let value = null

    document.getElementsByName("job_application[private_equity_experience]").forEach(element => {
      if (element.checked) {
        value = element.value
      }
    })

    return value
  }

  getExperienceInPrivateEquitySale() {
    let value = null

    document.getElementsByName("job_application[private_equity_sale_process_experience]").forEach(element => {
      if (element.checked) {
        value = element.value
      }
    })

    return value
  }

  getSectorExperienceValues() {
    return Array.from(document.getElementById("job_application_sector_experience").selectedOptions).map(option => option.value)
  }

  getRelocationPreferencesValues() {
    return Array.from(document.getElementById("job_application_relocation_preferences").selectedOptions).map(option => option.value)
  }

  saveProfileData() {
    const resume = $("#job_application_resume").prop("files")[0]
    const formData  = new FormData()
    formData.append("member_demographic[current_location]", $("#job_application_current_location").val())
    formData.append("member_demographic[current_title]", $("#job_application_current_title").val())
    formData.append("member_demographic[open_to_relocation]", this.getOpenToRelocationValue())
    formData.append("member_demographic[current_company_revenue]", $("#job_application_current_company_revenue").val())
    formData.append("member_demographic[experience_in_private_equity_company]", this.getExperienceInPrivateEquity())
    formData.append("member_demographic[experience_sale_process_at_private_equity_company]", this.getExperienceInPrivateEquitySale())
    formData.append("member_demographic[user_attributes][linkedin_url]", $("#job_application_linkedin_url").val())

    if (resume) {
      formData.append("member_demographic[user_attributes][resume]", resume)
    }

    this.getRelocationPreferencesValues().forEach(location => {
      formData.append("member_demographic[relocation_preferences][]", location)
    })

    this.getSectorExperienceValues().forEach(sector => {
      formData.append("member_demographic[sector_experience][]", sector)
    })

    fetch(`/account/users/${this.userIdValue}/member_demographics`, {
      method: "PATCH",
      body: formData,
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then(response => {
      if (response.ok) {
        return response.text()
      }

      throw new Error(`There was a problem when saving user profile data: ${response.statusText}`)
    }).then(html => {
      Turbo.renderStreamMessage(html)
    }).catch(error => {
      Honeybadger.notify(error)
    })
  }

  saveToProfile(event) {
    event.preventDefault()

    this.saveProfileData()
    this.saveToProfileButtonTarget.disabled = true
  }
}
