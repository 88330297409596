import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "kindSelect",
    "fileUploadFields",
    "eventFields",
    "reviewMaterialsFields",
    "formFields",
    "formQuestionTemplate",
    "formQuestions",
    "pollFields",
    "pollQuestionTemplate",
    "pollChoiceTemplate",
    "pollQuestions",
    "formFields",
    "urlTemplate",
    "urls"
  ]
  static values = {
    isAnswered: Boolean
  }

  connect() {
    if (this.hasKindSelectTarget) {
       const event = new Event("change", { bubbles: true })

      $(this.kindSelectTarget).on("select2:select", function() {
        this.dispatchEvent(event)
      })

      this.kindSelectTarget.dispatchEvent(event)
    }
  }

  disconnect() {
    if (this.hasKindSelectTarget) {
      $(this.kindSelectTarget).select2("destroy")
    }
  }

  onKindChange(event) {
    const select = event.target
    const selectedKind = select.value
    const targetName = select.options[select.selectedIndex].dataset.target
    const target = this[targetName]

    if (selectedKind) {
      target.classList.remove("hidden")
      this.hideFields(target)
      this.toggleFields(target)
    } else {
      this.hideFields(null)
      this.toggleFields(target)
    }
  }

  hideFields(selectedTarget) {
    document.querySelectorAll(".fields-group").forEach(element => {
      if (element !== selectedTarget) {
        element.classList.add("hidden")
      }
    })
  }

  toggleFields(selectedTarget) {
    const selector = ":input:not([data-permanently-disabled=true]), button:not([data-permanently-disabled=true])"

    $(".fields-group").each((index, element) => {
      if (element === selectedTarget) {
        $(element).find(selector).attr("disabled", false)
      } else {
        $(element).find(selector).attr("disabled", true)
      }
    })
  }

  toggleAllMembers(event) {
    event.preventDefault()
    const button = event.target
    const isGoingToSelectAll = button.textContent === "Select all"

    for (const checkbox of document.querySelectorAll("#group_membership_ids")) {
      checkbox.checked = isGoingToSelectAll
    }

    button.textContent = isGoingToSelectAll ? "Unselect all" : "Select all"
  }

  disablePoll() {
    $(this.pollQuestionsTarget).find(":input").attr("disabled", true)
    $(this.pollFieldsTarget).find("button").attr("disabled", true)
  }

  addPollQuestion(event) {
    event.preventDefault()
    const index = new Date().getTime()
    const content = $(this.pollQuestionTemplateTarget).html().replaceAll("{{index}}", index)
    $(this.pollQuestionsTarget).append(content)
    $(this.pollQuestionsTarget).find(":input").attr("disabled", false)
  }

  deletePollQuestion(event) {
    event.preventDefault()
    const index = $(event.target).data("index")
    const questionToRemove = $(this.pollQuestionsTarget).find(`.poll-question[data-index='${index}']`)
    questionToRemove.remove()
  }

  addPollChoice(event) {
    event.preventDefault()
    const questionIndex = $(event.target).data("index")
    const choiceIndex = new Date().getTime()
    const content = $(this.pollChoiceTemplateTarget).html()
      .replaceAll("{{question_index}}", questionIndex)
      .replaceAll("{{choice_index}}", choiceIndex)
    $(this.pollQuestionsTarget).find(`.poll-question[data-index='${questionIndex}'] .poll-choices`).append(content)
    $(this.pollQuestionsTarget).find(":input").attr("disabled", false)
  }

  deletePollChoice(event) {
    event.preventDefault()
    const choiceIndex = $(event.target).data("choiceIndex")
    const questionIndex = $(event.target).data("questionIndex")
    const choiceToRemove = $(this.pollQuestionsTarget).find(`.poll-question[data-index='${questionIndex}'] .poll-choice[data-choice-index='${choiceIndex}']`)
    choiceToRemove.remove()
  }

  addUrl(event) {
    event.preventDefault()
    const content = $(this.urlTemplateTarget).html()
    $(this.urlsTarget).append(content)
    $(".delete-url-button").first().show()
  }

  deleteUrl(event) {
    event.preventDefault()
    $(event.target).closest(".url").remove()

    if ($(".url").length === 1) {
      $(".delete-url-button").first().hide()
    }
  }

  addFormQuestion(event) {
    event.preventDefault()
    const content = $(this.formQuestionTemplateTarget).html()
    $(this.formQuestionsTarget).append(content)
    $(this.formQuestionsTarget).find(":input").attr("disabled", false)
    $(".delete-question-button").first().show()
  }

  deleteFormQuestion(event) {
    event.preventDefault()
    $(event.target).closest(".form-question").remove()

    if ($(".form-question").length === 1) {
      $(".delete-question-button").first().hide()
    }
  }
}
