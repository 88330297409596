import ModalController from "./modal_controller";

export default class extends ModalController {
  async submitForm() {
    const form = document.querySelector("#member-demographics-form")
    const formData = new FormData(form)
    const url = form.getAttribute("action")
    const method = formData.get("_method").toUpperCase()

    fetch(url, {
      method: method,
      body: formData,
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    })
      .then(response => {
        if (response.ok) {
          window.location.href = this.eventDetail.route
        } else {
          throw new Error("There was an error submitting the form")
        }
      })
      .catch(error => {
        console.log(error)
        Honeybadger.notify(error)
      })
  }

  dismiss() {
    super.dismiss()

    if (this.eventDetail?.route) {
      window.location.href = this.eventDetail.route
    }
  }
}
