import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []
  static values = {
    vimeoAccessToken: String
  }

  connect() {
    this.players = []
    const script = $("<script>").insertBefore($("script").first())
    script.on("load", this.initializeVimeoPlayers.bind(this))
    script.attr("src", "https://player.vimeo.com/api/player.js")
  }

  initializeVimeoPlayers() {
    let _this = this

    if (this.vimeoAccessTokenValue.length > 0) {
      $(".wp-block-embed.is-type-video.is-provider-vimeo iframe").each(function() {
        _this.players.push(new Vimeo.Player($(this)[0]))
      })

      document.addEventListener("segment-analytics-loaded", () => {
        analytics.ready(this.initializeVimeoAnalytics.bind(this))
      })
    }
  }

  initializeVimeoAnalytics() {
    this.players.forEach(player => {
      const vimeoAnalytics = new window.analytics.plugins.VimeoAnalytics(player, this.vimeoAccessTokenValue)
      vimeoAnalytics.initialize()
    })
  }
}
