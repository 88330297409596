import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fileInput",
    "uploadFileSpan",
    "clearFileButton",
    "downloadFileContainer",
    "uploadIcon",
    "fileRemovalInput"
  ]

  handleFileSelection() {
    this.uploadIconTarget.classList.remove("ti-upload")
    this.uploadIconTarget.classList.add("ti-check")
    this.uploadFileSpanTarget.innerText = "Select Another Document"

    if (this.hasDownloadFileContainerTarget) {
      this.downloadFileContainerTarget.remove()
    }
  }

  addFile(event) {
    event.preventDefault()
    this.fileInputTarget.click()
  }

  clearFile(event) {
    event.preventDefault()

    this.downloadFileContainerTarget.classList.add("hidden")
    this.clearFileButtonTarget.classList.add("hidden")
    this.fileRemovalInputTarget.value = true
  }
}
