import { Controller } from "@hotwired/stimulus"
import List from "list.js"

const fileAttributes = ["type", "upload-date", "name", "uploader"]

export default class extends Controller {
  static targets = ["myFiles", "groupFiles", "searchInput"]

  connect() {
    if (this.hasMyFilesTarget) {
      this.myFilesList = new List(this.myFilesTarget, {
        valueNames: fileAttributes
      })
    }

    this.groupFilesList = new List(this.groupFilesTarget, {
      valueNames: fileAttributes
    })
  }

  searchFiles({ target: { value } }) {
    if (this.myFilesList) {
      this.myFilesList.fuzzySearch(value, fileAttributes)
    }

    this.groupFilesList.fuzzySearch(value, fileAttributes)
  }
}
