import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "slide"
  ]
  static values = {
    resizeTarget: String
  }

  connect() {
    this.currentPosition = 0
    this.setEqualSlideHeight()
    this.updateSlides()
  }

  goToPreviousSlide(event) {
    event.preventDefault()

    if (this.currentPosition === 0) {
      this.currentPosition = this.slideTargets.length - 1
    } else {
      this.currentPosition--
    }

    this.updateSlides()
  }

  goToNextSlide(event) {
    event.preventDefault()

    if (this.currentPosition === (this.slideTargets.length - 1)) {
      this.currentPosition = 0
    } else {
      this.currentPosition++
    }

    this.updateSlides()
  }

  updateSlides() {
    this.slideTargets.filter((slide, index) => {
      if (index === this.currentPosition) {
        slide.classList.remove("hidden", "slide-offset")
        slide.querySelectorAll(".dot").forEach((dot, index) => {
          if (index === this.currentPosition) {
            dot.classList.add("bg-lime", "h-3", "w-3")
            dot.classList.remove("bg-gray-300", "h-2", "w-2")
          } else {
            dot.classList.add("bg-gray-300", "h-2", "w-2")
            dot.classList.remove("bg-lime", "h-3", "w-3")
          }
        })
      } else {
        slide.classList.add("hidden")
      }
    })
  }

  setEqualSlideHeight() {
    let greaterHeight = 0
    const resizeTarget = this.resizeTargetValue

    this.slideTargets.forEach(slide => {
      if ($(slide).find(this.resizeTargetValue).height() > greaterHeight) {
        greaterHeight = $(slide).find(resizeTarget).height()
      }
    })

    this.slideTargets.forEach(slide => {
      $(slide).find(resizeTarget).height(greaterHeight)
    })
  }
}
