import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "tagDropdown",
    "selectedTagsContainer",
    "selectedTagTemplate",
    "tagInput",
    "sortInput",
    "form",
    "searchResultsHeader"
  ]
  static values = {
    search: String,
    sortBy: String
  }

  connect() {
    let _this = this
    const tagDropdown = $(this.tagDropdownTarget)
    this.selectedTags = $(this.tagInputTarget).val().split(",").filter(item => item)

    $(document).click(function() {
      tagDropdown.find("ul:visible").slideToggle("fast")
    })

    tagDropdown.click(function(event) {
      event.stopPropagation()

      tagDropdown.find("ul").slideToggle("fast")
    })

    tagDropdown.find("li").click(function(event) {
      _this.setValue($(this))
      _this.formTarget.submit()
    })

    if (this.searchValue.length > 0 || this.sortByValue.length > 0 || this.selectedTags.length > 0) {
      setTimeout(() => {
        this.searchResultsHeaderTarget.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }

  unselectTag(event) {
    const value = $(event.currentTarget).data("value").toString()
    const tag = $(this.tagDropdownTarget).find(`li[data-value='${value}']`)
    this.removeValue(tag)
    this.formTarget.submit()
  }

  sortByLatest(event) {
    this.sortInputTarget.value = "latest"
    this.formTarget.submit()
  }

  sortByPopular(event) {
    this.sortInputTarget.value = "popular"
    this.formTarget.submit()
  }

  removeValue(tag) {
    const value = tag.data("value").toString()
    tag.find(".tag-check-icon").addClass("hidden")
    this.selectedTags = this.selectedTags.filter(item => item && item !== value)
    $(this.tagInputTarget).val(this.selectedTags)
    $(this.selectedTagsContainerTarget).find(`[data-value='${value}']`).remove()
  }

  setValue(tag) {
    const value = tag.data("value").toString()
    const text = tag.data("text")
    const content = $(this.selectedTagTemplateTarget).html()
      .replaceAll("{{text}}", text)
      .replaceAll("{{value}}", value)
    $(this.tagDropdownTarget).find("li").not(tag).find(".tag-check-icon").addClass("hidden")
    tag.find(".tag-check-icon").removeClass("hidden")
    this.selectedTags = [value]
    $(this.tagInputTarget).val(this.selectedTags)
    $(this.selectedTagsContainerTarget).html(content)
  }
}
