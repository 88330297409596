import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    setTimeout(() => {
      this.messageTarget.remove()
    }, 15000)
  }

  dismiss(event) {
    event.currentTarget.parentElement.remove()
  }
}
