import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cartItem", 'purchaseComplete']

  addToCart(event) {
    if (typeof fbq === "function") fbq("track", "AddToCart", { product_id: event.target.dataset.productId });

    this.cartItemTarget.classList.remove("hidden")
    const originalTop = this.cartItemTarget.offsetTop
    const originalLeft = this.cartItemTarget.offsetLeft
    const shoppingCart = document.querySelector("#shopping_cart")
    const shoppingCartTop = shoppingCart.offsetTop
    const shoppingCartLeft = shoppingCart.offsetLeft
    this.cartItemTarget.style.position = "absolute"
    this.cartItemTarget.style.top = `${originalTop}px`
    this.cartItemTarget.style.left = `${originalLeft}px`
    this.cartItemTarget.style.transform = `translate(${shoppingCartLeft - originalLeft}px, ${shoppingCartTop - originalTop}px)`

    setTimeout(() => {
      this.cartItemTarget.classList.add("hidden")
      this.cartItemTarget.style.transform = ""
    }, 3000)
  }

  purchaseCompleteTargetConnected(_event) {
    if (typeof fbq === "function") fbq("track", "Purchase", { order_id: this.purchaseCompleteTarget.dataset.orderId });
  }
}
