import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleMembersCheckboxes(isChecked) {
    for (const checkbox of document.querySelectorAll("#group_membership_ids")) {
      checkbox.checked = isChecked
    }
  }

  cancelReminder(event) {
    event.preventDefault()
    this.toggleMembersCheckboxes(false)
  }

  toggleAllMembers(event) {
    event.preventDefault()
    const button = event.target
    const isGoingToSelectAll = button.textContent === "Select all"
    this.toggleMembersCheckboxes(isGoingToSelectAll)
    button.textContent = isGoingToSelectAll ? "Unselect all" : "Select all"
  }
}
